import Label from "components/Label/Label";
import React, { FC, useContext, useState, useEffect } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { UserContext } from '../../context/userContext';
import Company from "../../interfaces/Company";
import User from "../../model/User";
import { getIdToken } from '../../auth/magic';
import { states } from "../../common/data";
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailRegex from 'email-regex';
import StagingKeyDropdown from "components/NftMoreDropdown";
export interface CompanyPageProps {
  className?: string;
}


const CompanyPage: FC<CompanyPageProps> = ({ className = "" }) => {

  const { user } = useContext(UserContext);


  //Form fields
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [companyDID, setCompanyDID] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [email, setEmail] = useState<string >("");
  const [description, setDescription] = useState<string >("");
  const [website, setWebsite] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [facebook, setFacebook] = useState<string>("");
  const [mUser, setUser] = useState<User | undefined>();
  const [imgUrl, setImgUrl] = useState<string>("https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg");

  const toastConfig: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }


  const getUserCompany = async (issuer: string) => {
    setLoading(true);
    const did_token = await getIdToken();
    const _User = new User(did_token, issuer, null, null, null, null, null);
    await _User.get();

    if(_User.companyDID !== null){
      //Let's pull the company
      await _User.getCompany();
      console.log(_User.company);

      if(_User.company !==null){
        setName(_User.company.name !== null ? _User.company.name : "");
        setState(_User.company.state);
        setEmail(_User.company.email !== null ? _User.company.email : "");
        setDescription(_User.company.description !== null ? _User.company.description : "");
        setWebsite(_User.company.website !== null ? _User.company.website : "");
        setTwitter(_User.company.twitter !== null ? _User.company.twitter : "");
        setFacebook(_User.company.facebook !== null ? _User.company.facebook : "");
        setCompanyDID(_User.company.state + ":" + _User.company.companyId)
      }
      
      setDisabled(true);
      setImgUrl("");
    }

    setUser(_User);
    
    console.log(did_token);

    setLoading(false);
  };

  const addCompany = async () =>{
    //Set the fact we are processing
    setLoading(true);

    //Make sure all the values are completed

    if (name === "" || name === "0") {
      toast.error("Please enter a Company/Team name", toastConfig);

      setLoading(false);

      return false;
    };

    if (name.length < 3) {
      toast.warn("Company/Team name must contain a minimum characters", toastConfig);

      setLoading(false);

      return false;
    };

    if (state === "" || state === "0") {
      toast.error("Please select a State", toastConfig);

      setLoading(false);

      return false;
    };

    if (email === "" || email === "0") {
      toast.error("Please enter an email address", toastConfig);

      setLoading(false);

      return false;
    };
    
    if (emailRegex({exact: true}).test(email) === false) {
      toast.warn("Please enter a valid email address", toastConfig);

      setLoading(false);

      return false;
    };


    //Build a company interface
    const iCompany: Company = {
      state: state,
      name: name,
      email: email,
      description: description,
      website: website,
      twitter: twitter,
      facebook: facebook,
      status: null,
      companyId: null,
      companyuuid: null,
      developer: null,
      timeStamp: null,
      isoDate: null,
      week: null,
      created: null,
      updatedAt: null,
    };

    if (typeof mUser === "undefined") {
      //If we don't have a user class load it, as we will need it
      const did_token = await getIdToken();
      const _User = new User(
        did_token,
        user?.issuer,
        null,
        null,
        null,
        null,
        null
      );
      await _User.get();

      toast.promise(_User.addCompany(iCompany), {
        pending: "Creating Company",
      });

    } else {
      //Since we have a user class already
      toast.promise(mUser.addCompany(iCompany), {
        pending: "Creating Company",
      });
    }

    setLoading(false);
  }

  useEffect(() => { 

    if(user !== null && user.issuer !== null){
      toast.promise(
        getUserCompany(user?.issuer),
        {
          pending: 'Loading Company'
        }
    )
    }

   }, [user]);


  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>{companyDID !== null ? name : "New Company"} || Backpac</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              {companyDID !== null ? "Edit " : "New "} Company
            </h2>
            {companyDID !== null ? (
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {description}
              </span>
            ) : (
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                Your Developer account needs a company or Team. You can set
                preferred display name, add company/team logo and manage other
                company/team settings.
              </span>
            )}
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={imgUrl} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Logo</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            </div>

            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
              {companyDID !== null ? (
                <div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end">
                  <ButtonSecondary onClick={addCompany} disabled={loading}>
                    View
                  </ButtonSecondary>
                </div>
              ) : (
                ""
              )}
              {/* ---- */}
              <div>
                <Label>Company | Team Name</Label>
                <Input
                  name="name"
                  className="mt-1.5"
                  value={name}
                  disabled={loading}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div>
                <Label>State</Label>
                <select
                  className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5`}
                  value={state}
                  disabled={
                    disabled === true || loading === true ? true : false
                  }
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                >
                  <option key={"0"} value={"0"}>
                    Select a State
                  </option>
                  {states.map(function (item, i) {
                    return (
                      <option key={i} value={item.abbreviation}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* ---- */}
              <div>
                <Label>Email</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-envelope"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    disabled={loading}
                  />
                </div>
              </div>

              {/* ---- */}
              <div>
                <Label>Description</Label>
                <Textarea
                  name="description"
                  rows={5}
                  className="mt-1.5"
                  placeholder="Something about the company in a few word."
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>

              <div className="">
                <Label>Website</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    https://
                  </span>
                  <Input
                    value={website}
                    name={website}
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                    disabled={loading}
                    className="!rounded-l-none"
                    placeholder="acme.com"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-2.5">
                <div>
                  <Label>Facebook</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl lab la-facebook-f"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      placeholder="yourfacebook"
                      sizeClass="h-11 px-4 pl-2 pr-3"
                      value={facebook}
                      name={facebook}
                      onChange={(e) => {
                        setFacebook(e.target.value);
                      }}
                      disabled={loading}
                    />
                  </div>
                </div>
                <div>
                  <Label>Twitter</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl lab la-twitter"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      placeholder="yourtwitter"
                      sizeClass="h-11 px-4 pl-2 pr-3"
                      value={twitter}
                      name={twitter}
                      onChange={(e) => {
                        setTwitter(e.target.value);
                      }}
                      disabled={loading}
                    />
                  </div>
                </div>
                {/* <div>
                  <Label>Telegram</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl lab la-telegram-plane"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      placeholder="yourtelegram"
                      sizeClass="h-11 px-4 pl-2 pr-3"
                    />
                  </div>
                </div> */}
              </div>

              {/* ---- ButtonSecondary */}

              <div className="pt-2">
                {companyDID !== null ? (
                  <ButtonSecondary
                    className="w-full"
                    onClick={addCompany}
                    disabled={loading}
                  >
                    Update Company | Team
                  </ButtonSecondary>
                ) : (
                  <ButtonPrimary
                    className="w-full"
                    onClick={addCompany}
                    disabled={loading}
                  >
                    Create Company | Team
                  </ButtonPrimary>
                )}
              </div>
            </div>
          </div>

          {/* HEADER */}

          {companyDID !== null ? (
            <div
              className="w-full"
              style={{ marginTop: "6em", marginLeft: "3em" }}
            >
              <div className="container -mt-10 lg:-mt-16">
                <div className="bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
                  <div className="w-full lg:w-full flex-shrink-0 mt-12 sm:mt-0">
                    <h2 className="inline-flex items-center text-2xl sm:text-2xl lg:text-2xl font-semibold">
                      <span>Staging</span>
                    </h2>
                    <span style={{marginLeft: '2em'}} className="text-neutral-500 dark:text-neutral-400  text-md">https://staging.api.backpac.xyz</span>
                    <div
                      className={`MySwitch flex fle justify-between items-center space-x-2 ${className}`}
                    >
                      <div>
                        <p className="text-black-800 dark:text-neutral-400  text-md justify-left">
                          Username
                        </p>
                      </div>
                      <div>
                        <p className="text-black-800 dark:text-neutral-400  text-md justify-left">
                         Key
                        </p>
                      </div>
                      <div>
                        <p className="text-black-800 dark:text-neutral-400  text-md justify-left">
                         Address
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div
                      className={`MySwitch flex fle justify-between items-center space-x-2 ${className}`}
                    >
                      <div>
                        <p className="text-neutral-500 dark:text-neutral-400  text-md">1234ABCD</p>
                      </div>
                      <div>
                        <p className="text-neutral-500 dark:text-neutral-400  text-md justify-left">SFSDFS86SFHK3R2RJLWJ</p>
                      </div>
                      <div>
                        <p className="text-neutral-500 dark:text-neutral-400  text-xs">05f3c930-31a4-4322-800a-548054a40c48</p>
                      </div>
                    </div>
                  </div>
                  <div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end">
                    <StagingKeyDropdown
                      actions={[
                        {
                          id: "create",
                          name: "Create Key",
                          icon: "las la-key",
                        },
                      ]}
                      containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* ====================== END HEADER ====================== */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CompanyPage;
