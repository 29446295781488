import React, { useContext } from 'react';
import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";
import { UserContext } from '../context/userContext';

const SiteHeader = () => {

  const { isLoggedIn } = useContext(UserContext);

  return isLoggedIn  ? <HeaderLogged /> : <Header2 />;
};

export default SiteHeader;
