import { createContext } from 'react';

interface MagicLinkUser {
  email: string;
  isMfaEnabled: boolean | null;
  issuer: string | null;
  phoneNumber: string | null;
  publicAddress: string;
}

interface UserData {
    isLoggedIn: boolean | null;
    email: string | null;
    user: MagicLinkUser | null
  }
export const UserContext = createContext<UserData>({ isLoggedIn: null, email: '', user: null })