import React from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
//import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
//import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
//import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
//import SectionVideos from "./SectionVideos";
import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import Vector1 from "images/Vector1.png";
//import SectionLargeSlider from "./SectionLargeSlider";
//import SectionSliderCollections from "components/SectionSliderCollections";
//import SectionGridFeatureNFT from "./SectionGridFeatureNFT";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Connect with your favorite brands and discover exclusive digital assets with BackPac™</title>
        <meta name="description" content="Experience a new way to connect with your favorite brands and discover exclusive perks with BackPac™. Our web3 platform makes it easy for brands to deliver personalized digital assets and offers based on your interests and preferences. Sign up now and start exploring the world of BackPac™ to see what your favorite brands have to offer and discover new brands you'll love"></meta>
        <meta name="keywords" content="Brand loyalty, digital assets, personalized offers, perks, web3 technology, consumer engagement, exclusive access, real-life events"></meta>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-20 mt-12 mb-20 sm:space-y-24 sm:my-24 lg:space-y-32 lg:my-32">
        {/* SECTION HERO */}
        <SectionHero
          className="pb-10"
          heading={
            <span>
              Discover 🖼
              <br /> collect, and utilize <br /> extraordinary {` `}
              <span className="relative pr-3">
                <img
                  className="w-full absolute bottom-3 -left-1"
                  src={Vector1}
                  alt="Vector1"
                />
                <span className="relative">NFTs</span>
              </span>
            </span>
          }
        />

        {/* SECTION 2 */}
        <SectionHowItWork />
      </div>

      {/* SECTION LAERGE SLIDER 
      <div className="bg-neutral-100/80 dark:bg-black/20 py-20 lg:py-32">
        <div className="container">
          <SectionLargeSlider />
        </div>
      </div>
      */}

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION 
        <SectionGridAuthorBox boxCard="box3" />
*/}
        {/* SECTION 
        <div className="relative py-20 lg:py-28">
          <BackgroundSection />
          <SectionSliderCollections />
        </div>
        */}

        {/* SECTION 
        <SectionGridFeatureNFT />
        */}

        {/* SECTION 
        <div className="relative py-20 lg:py-24">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>
        */}

        {/* SECTION */}
        <SectionSubscribe2 />
        

        {/* SECTION 1 */}
        <SectionSliderCategories />

        {/* SECTION 
        <SectionVideos />
        */}
      </div>
    </div>
  );
}

export default PageHome;
