import React, {  useState, useEffect} from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import PrivateRoute from '../auth/PrivateRoute';
import { UserContext } from '../context/userContext';
import { checkUser } from '../auth/magic';
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import DigitalAssets from "containers/AuthorPage/DigitalAssets";
import AccountPage from "containers/AccountPage/AccountPage";
import CompanyPage from "containers/CompanyPage/CompanyPage";
import BrandPage from "containers/CompanyPage/BrandPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import AssetDetail from "containers/NftDetailPage/AssetDetail";
import Collection from "containers/Collection";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import Scan from "containers/Scan";

export const pages: Page[] = [
  { path: "/", component: PageHome, isSecure: false },
  { path: "/#", component: PageHome2, isSecure: false },
  { path: "/home2", component: PageHome, isSecure: false },
  { path: "/home3", component: PageHome3, isSecure: false },
  { path: "/home-header-2", component: PageHome, isSecure: false },
  { path: "/digital-asset/:chain/:tokenType/:contractAddress/:tokenId", component: AssetDetail, isSecure: false },
  { path: "/collection/:chain/:contractAddress", component: Collection, isSecure: false },
  { path: "/page-search", component: PageSearch, isSecure: false },
  { path: "/digital-assets", component: DigitalAssets, isSecure: true },
  { path: "/scan", component: Scan, isSecure: true },
  { path: "/account", component: AccountPage, isSecure: true },
  { path: "/company", component: CompanyPage, isSecure: true },
  { path: "/company/:companyDID", component: BrandPage, isSecure: true },
  { path: "/page-upload-item", component: PageUploadItem, isSecure: false },
  { path: "/connect-wallet", component: PageConnectWallet, isSecure: false },
  { path: "/blog", component: BlogPage, isSecure: false },
  { path: "/blog-single", component: BlogSingle, isSecure: false },
  { path: "/contact", component: PageContact, isSecure: false },
  { path: "/about", component: PageAbout, isSecure: false },
  { path: "/signup", component: PageSignUp, isSecure: false },
  { path: "/login", component: PageLogin, isSecure: false },
  { path: "/subscription", component: PageSubcription, isSecure: true },
];

const MyRoutes = () => {

  interface MagicLinkUser {
    email: string;
    isMfaEnabled: boolean | null;
    issuer: string | null;
    phoneNumber: string | null;
    publicAddress: string;
  }
  interface UserData {
    isLoggedIn: boolean | null;
    email: string | null;
    user: MagicLinkUser | null;
  };

  const [user, setUser] = useState<UserData>({ isLoggedIn: null, email: '', user: null });


  useEffect(() => { 
    const validateUser = async () => {
    
      try { 
        await checkUser(setUser);
       } catch (error) {
         console.error(error);
       }
     };
     validateUser();
   }, []);

  return (
    <UserContext.Provider value={user}>
    <BrowserRouter
      basename={process.env.NODE_ENV === "production" ? "/" : "/"}
    >
      <ScrollToTop />
      <SiteHeader />
      <Routes>


      {pages.map(({ component, path, isSecure }) => {
          const Component = component;
          
          
          if(isSecure === true && user.isLoggedIn === false){
            return <Route key={path} element={<PrivateRoute key={path} component={<Component />} path={path} />} path={path} />;
          }

          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
       
      </Routes>
      <Footer />
    </BrowserRouter>
    </UserContext.Provider>
  );
};

export default MyRoutes;
