import React, { FC, useState,   } from "react";
import { Alert } from 'reactstrap';
//import facebookSvg from "images/Facebook.svg";
//import twitterSvg from "images/Twitter.svg";
//import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
//import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { loginUser, getIdToken } from '../../auth/magic';
//import lscache from "lscache";
export interface PageLoginProps {
  className?: string;
}

// const loginSocials = [
//   {
//     name: "Continue with Facebook",
//     href: "#",
//     icon: facebookSvg,
//   },
//   {
//     name: "Continue with Twitter",
//     href: "#",
//     icon: twitterSvg,
//   },
//   {
//     name: "Continue with Google",
//     href: "#",
//     icon: googleSvg,
//   },
// ];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {


  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (!email) {
      setLoading(false);
      setError("Email is Invalid");
      return;
    }
    try {
      await loginUser(email);

      const did_token = await getIdToken();
      //setDidToken(did_token);

      //console.log('did_token',did_token);

      const endpoint = require("../../common/endpoint");

      endpoint
        ._postWithAdditionalParams(
          `${process.env.REACT_APP_BASE_API_URL}/user`,
          {},
          {
            headers: { did_token, platform: "desktop" },
            queryParams: {},
          }
        )
        .then((result: any) => {
          console.log(result);
          if (result.data.success === true) {
            window.location.replace("/digital-assets");
          } else {
            setError(result.data.message);
            setLoading(false);
          }
        })
        .catch((error: any) => {
          setError(error.message);
          setLoading(false);
        });
    } catch (error) {
      setError("Unable to log in");
      setLoading(false);
      console.error(error);
    }
  };
  const handleChange = (event:any) => {
    setEmail(event.target.value);
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Backpac, Inc</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login / Register
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={handleChange}
                disabled={loading}
              />
            </label>
            {/* <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm text-green-600">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1" />
            </label> */}
            <ButtonPrimary disabled={loading} type="submit">
              Continue
            </ButtonPrimary>
          </form>
          {typeof error !== "undefined" ? (
            <Alert key={"login"} variant={"danger"}>
              {error}
            </Alert>
          ) : null}
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <span className="text-green-600">Create an account today</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
