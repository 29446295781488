import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import SignUp from "images/icons/sign-up.png";
import Discover from "images/icons/loupe.png";
import Claim from "images/icons/claim.png";
import DigitalAsset from "images/icons/digital.png";
import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";

export interface SectionHowItWorkProps {
  className?: string;
  data?: typeof DEMO_DATA[0][];
}

const DEMO_DATA = [
  {
    id: 1,
    img: SignUp,
    imgDark: SignUp,
    title: "Sign Up",
    desc: "Join the revolution and discover a new way to connect with your favorite brands! With BackPac™, you'll get access to exclusive digital assets and personalized perks, all tailored to your interests and preferences",
  },
  {
    id: 2,
    img: Discover,
    imgDark: Discover,
    title: "Discover",
    desc: "With BackPac™, it's easy to stay up-to-date on the latest offers and promotions from your favorite brands and discover new brands that may be of interest to you.",
  },
  {
    id: 3,
    img: Claim,
    imgDark: Claim,
    title: "Claim",
    desc: "Claiming digital assets with perks is easy - just follow the prompts and instructions provided in the email or notification you receive.",
  },
  {
    id: 4,
    img: DigitalAsset,
    imgDark: DigitalAsset,
    title: "Use your Digital Asset",
    desc: "Utilizing the perks and rewards of digital assets: Once you have claimed digital assets with perks from a brand through BackPac™, you can start using them to their full potential.",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <div className="relative grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20">
        <img
          className="hidden md:block absolute inset-x-0 -top-1"
          src={VectorImg}
          alt="vector"
        />
        {data.map((item: typeof DEMO_DATA[number], index: number) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-5 sm:mb-10 lg:mb-20 max-h-[96px] max-w-[200px] mx-auto"
              src={item.img}
            />
            <div className="text-center mt-auto space-y-5">
              <Badge
                name={`Step ${index + 1}`}
                color={
                  !index
                    ? "blue"
                    : index === 1
                    ? "pink"
                    : index === 2
                    ? "yellow"
                    : "green"
                }
              />
              <h3 className="text-lg font-semibold">{item.title}</h3>
              <span className="block text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
