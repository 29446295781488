//import ButtonDropDownShare from "components/ButtonDropDownShare";
import { FC } from "react";
import NftMoreDropdown from "components/NftMoreDropdown";
import OwnerNftMoreDropdown from "components/OwnerNftMoreDropdown";
import React from "react";

export interface LikeSaveBtnsProps {
  owned?: boolean;
}

const LikeSaveBtns: FC<LikeSaveBtnsProps> = ({owned}) => {

  if(owned === true){
    return (
      <div className="flow-root">
        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
          <OwnerNftMoreDropdown />
        </div>
      </div>
    );
  }

  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        {/* <ButtonDropDownShare panelMenusClass="!w-52" /> */}
        <NftMoreDropdown />
      </div>
    </div>
  );

  

};

export default LikeSaveBtns;
